import React, { useContext, useEffect } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// reactstrap components11
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  CardBody,
  FormGroup,
  Form,
  Modal,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import axios from 'axios';
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import NotificationAlert from "react-notification-alert";
import { FileUploader } from "react-drag-drop-files";
import ReactLoading from "react-loading";
import moment from "moment";
import baseApi1 from "../../api/BaseApi1";
import { baseURL1 } from "../../api/baseURL.json";

const fileTypes = ["PDF", "MP4", "PNG", "JPG", "MP3", "JPEG"];

const { SearchBar } = Search;
const AddGuideline = () => {
  const [showPerPage, setShowPerPage] = React.useState(null);
  //Edit Communication
  const [trainingName, setTrainingName] = React.useState("");
  const [traningDate, setTraningDate] = React.useState("");
  const [trainerName, setTrainerName] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [editFile, setEditFile] = React.useState(null);
  //Add Communication
  const [newTrainingName, setNewTrainingName] = React.useState("");
  const [newTraningDate, setNewTraningDate] = React.useState("");
  const [newTrainerName, setNewTrainerName] = React.useState("");
  const [newStatus, setNewStatus] = React.useState("");
  const [newFile, setNewFile] = React.useState(null);
  //Others
  const [formModal, setformModal] = React.useState(false);
  const [addGuidelineModal, setGuidelineModal] = React.useState(false);
  const [allGuideline, setAllGuideline] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [singleGuideline, setSingleGuideline] = React.useState({});
  const [singleTrainingDate, setSingleTrainingDate] = React.useState("");

  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "bc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Guideline Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const getGuidelineData = async () => {
    try {
      setIsLoading(true);
      const response = await baseApi1.get("/api/guideline/all");
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setIsLoading(false);
        setAllGuideline(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.response);
    }
  };
  const getOneGuidelineData = (id) => {
    const singleGuidelineFinal = allGuideline.find((x) => x._id === id);
    setSingleGuideline(singleGuidelineFinal);
    setSingleTrainingDate(singleGuidelineFinal?.trainingDate);
  };
  const deleteOneGuideline = async (id) => {
    try {
      const response = await axios.delete(
        `${baseURL1}:3000/api/guideline/${id}`
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        getGuidelineData();
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  const updateOneGuideline = async (id) => {
    const editedGuideline = new FormData();
    editedGuideline.append(
      "trainingName",
      trainingName === "" ? singleGuideline?.trainingName : trainingName
    );
    editedGuideline.append(
      "trainingDate",
      traningDate === "" ? singleGuideline?.trainingDate : traningDate
    );
    editedGuideline.append(
      "trainerName",
      trainerName === "" ? singleGuideline.trainerName : trainerName
    );
    editedGuideline.append(
      "file",
      editFile === null ? singleGuideline.file : editFile
    );
    editedGuideline.append(
      "trainingPublishStatus",
      status === "" ? singleGuideline.trainingPublishStatus : status
    );
    try {
      const response = await axios.put(
        `${baseURL1}:3000/api/guideline/${id}`,
        editedGuideline,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getGuidelineData();
        setformModal(false);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getGuidelineData();
  }, []);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="all">All</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const addNewGuideline = async () => {
    const newGuidelineData = new FormData();
    newGuidelineData.append("trainingName", newTrainingName);
    newGuidelineData.append("trainingDate", newTraningDate);
    newGuidelineData.append("trainerName", newTrainerName);
    newGuidelineData.append("file", newFile);
    newGuidelineData.append("trainingPublishStatus", newStatus);
    try {
      const response = await axios.post(
        `${baseURL1}:3000/api/guideline`,
        newGuidelineData,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", "Some Thing Went Wrong");
      } else {
        getGuidelineData();
        response.status === 200 || response.status === 201
          ? setGuidelineModal(false)
          : setGuidelineModal(true);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const submitNewGuideline = () => {
    addNewGuideline();
  };
  const handleSingleGuidelineDelete = (id) => {
    deleteOneGuideline(id);
  };

  const handleAddFile = (file) => {
    setNewFile(file);
  };
  const handleEditFile = (file) => {
    setEditFile(file);
  };
  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Guideline List" parentName="Guideline" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Guideline</h3>
                <Button
                  onClick={() => setGuidelineModal(true)}
                  style={{ backgroundColor: "#DB5A3B", color: "white" }}
                >
                  Add New
                </Button>
              </CardHeader>
              <ToolkitProvider
                data={allGuideline}
                keyField="trainingName"
                columns={[
                  {
                    dataField: "#",
                    text: "SL",
                    formatter: (cell, row, rowIndex) => {
                      return rowIndex + 1;
                    },
                  },
                  {
                    dataField: "trainingName",
                    text: "Training Name",
                    sort: true,
                  },
                  {
                    dataField: "trainingDate",
                    text: "Training Date",
                    sort: true,
                    formatter: (cell) => {
                      return new Date(cell).toDateString();
                    },
                  },
                  {
                    dataField: "trainerName",
                    text: "Trainer Name",
                    sort: true,
                  },
                  {
                    dataField: "file",
                    text: "File Name",
                    sort: true,
                  },
                  {
                    dataField: "_id",
                    text: "Action",
                    formatter: (cell) => {
                      const handleEdit = () => {
                        getOneGuidelineData(cell);
                        setformModal(true);
                      };
                      const action = (
                        <div>
                          <Button color="info" onClick={handleEdit}>
                            Edit
                          </Button>
                          <Button
                            onClick={() => handleSingleGuidelineDelete(cell)}
                            color="danger"
                          >
                            Delete
                          </Button>
                        </div>
                      );
                      return action;
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={4} sm={4}>
                          <div>
                            <select
                              name="datatable-basic_length"
                              aria-controls="datatable-basic"
                              className="form-control form-control-sm w-25"
                              onChange={(e) => setShowPerPage(e.target.value)}
                            >
                              <option
                                selected={showPerPage === 10 && true}
                                value={10}
                              >
                                10
                              </option>
                              <option
                                selected={showPerPage === 25 && true}
                                value={25}
                              >
                                25
                              </option>
                              <option
                                selected={showPerPage === 50 && true}
                                value={50}
                              >
                                50
                              </option>
                              <option
                                selected={showPerPage === 100 && true}
                                value={100}
                              >
                                100
                              </option>
                              <option
                                selected={showPerPage === "all" && true}
                                value="all"
                              >
                                all
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col xs={4} sm={4}>
                          <ButtonGroup>
                            <Button
                              className="buttons-copy buttons-html5"
                              color="warning"
                              size="sm"
                              id="copy-tooltip"
                              onClick={() =>
                                window.open(
                                  `${baseURL1}/api/guideline/csv`
                                )
                              }
                            >
                              <span>CSV</span>
                            </Button>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  color="warning"
                                  size="sm"
                                  className="buttons-copy buttons-html5"
                                  id="print-tooltip"
                                >
                                  Print
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                          </ButtonGroup>
                          <UncontrolledTooltip
                            placement="top"
                            target="print-tooltip"
                          >
                            This will open a print page with the visible rows of
                            the table.
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            placement="top"
                            target="copy-tooltip"
                          >
                            This will Download Brand Communication As CSV.
                          </UncontrolledTooltip>
                        </Col>
                        <Col xs={4} sm={4}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    {!isLoading && Boolean(allGuideline.length) ? (
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        id="react-bs-table"
                      />
                    ) : <div className="d-flex justify-content-center">No data found</div>}
                    {isLoading && (<div className="d-flex justify-content-center">
                      <ReactLoading
                        className=""
                        type="bubbles"
                        color="#DB5A3B"
                        height={200}
                        width={100}
                      />
                    </div>)}
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Edit Modal */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModal}
        toggle={() => setformModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Update Guideline
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup
                  className={classnames("mb-3", {
                    focused: trainingName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Training Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Training Name"
                      defaultValue={singleGuideline?.trainingName}
                      type="text"
                      onChange={(e) => setTrainingName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                    md="2"
                  >
                    Training Date
                  </label>
                  <Input
                    id="example-date-input"
                    value={
                      traningDate === "" ? singleTrainingDate : traningDate
                    }
                    type="date"
                    onChange={(e) =>
                      setTraningDate(
                        moment(new Date(e.target.value)).format("yyyy-MM-DD")
                      )
                    }
                  />
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: trainerName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Trainer Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Trainer Name"
                      defaultValue={singleGuideline?.trainerName}
                      type="text"
                      onChange={(e) => setTrainerName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Status
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setStatus(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    <option
                      selected={singleGuideline?.status === "active" && true}
                      value="active"
                    >
                      Active
                    </option>
                    <option
                      selected={singleGuideline?.status === "inactive" && true}
                      value="inactive"
                    >
                      Inactive
                    </option>
                  </Input>
                </FormGroup>
                <div className="mt-3">
                  <FileUploader
                    handleChange={handleEditFile}
                    label="Upload Training Materials"
                    name="file"
                    types={fileTypes}
                  />
                  <p>
                    {editFile
                      ? `File name: ${editFile?.name}`
                      : "no files uploaded yet"}
                  </p>
                </div>
                <div className="text-center">
                  <Button
                    onClick={() => setformModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={() => updateOneGuideline(singleGuideline?._id)}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      {/* Add Guideline */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={addGuidelineModal}
        toggle={() => setGuidelineModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Add Guideline
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup
                  className={classnames("mb-3", {
                    focused: newTrainingName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Training Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Training Name"
                      type="text"
                      onChange={(e) => setNewTrainingName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                    md="2"
                  >
                    Training Date
                  </label>
                  <Input
                    id="example-date-input"
                    type="date"
                    onChange={(e) =>
                      setNewTraningDate(
                        moment(new Date(e.target.value)).format("yyyy-MM-DD")
                      )
                    }
                  />
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: newTrainerName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Trainer Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Trainer Name"
                      type="text"
                      onChange={(e) => setNewTrainerName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Status
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewStatus(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Input>
                </FormGroup>
                <div className="mt-3">
                  <FileUploader
                    handleChange={handleAddFile}
                    label="Upload Communication Video"
                    name="file"
                    types={fileTypes}
                  />
                  <p>
                    {newFile
                      ? `File name: ${newFile?.name}`
                      : "no files uploaded yet"}
                  </p>
                </div>
                <div className="text-center">
                  <Button
                    onClick={() => setGuidelineModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={submitNewGuideline}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Add
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default AddGuideline;
